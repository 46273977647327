import * as React from "react"
import styled from "@emotion/styled"
import gs from "../components/styling/GlobalStyles"
import Seo from "../components/Seo"
import Layout from "../components/Layout"
import { StaticImage } from "gatsby-plugin-image"
import Title from "../components/styling/Title"

// #region styling
const DivTwoColumn = styled.div`
  display: flex;
  gap: 16px;
  flex-wrap: wrap;
  @media all and (min-width: ${gs.size.screen.md}) {
    flex-wrap: nowrap;
  }
`
const H3Small = styled.h3`
  font-size: ${gs.size.font.xxl};
`
const H4 = styled.h4`
  ${gs.font.default};
  font-size: ${gs.size.font.lg};
  color: ${gs.color.graey};
`
const DivTextBody = styled.div`
  max-width: 820px;
`
const Aside = styled.aside`
  display: flex;
  flex-flow: column wrap;
  gap: 8px 0;
`
// #endregion

// ! Edit page title here so it updates the title as well as SEO !
const pageTitle = "About"

/**
 * @returns About page!
 */
const AboutPage = () => {
  return (
    <Layout>
      <section>
        <Title>{pageTitle}</Title>
        <DivTwoColumn>
          <DivTextBody>
            <p>
              I've been composing for media for over 10 years. My music spans a
              wide variety of moods and styles, although I have a special place
              in my heart for minimalism and chamber groups. I'm also a huge
              nerd for interactive music — nothing is more exciting to me than
              figuring out systems for combining music layers and segments to
              create a unique listening experience for each user.
            </p>
            <p>
              I love collaborating with people who are making cool things. If
              you need music for your game, animation, film, podcast, or other
              media, let's talk! Reach out at megan@megancarnesmusic.com
            </p>
            <p>For the tech nerds: I use Cubase, Wwise, and Unity.</p>
            <section>
              <H3Small>Speaking, writing, audio community work, etc.</H3Small>
              <ul>
                <li>
                  GDC - Speaker, “Game Composer's Toolbox: Interactive Music
                  Tips & Tricks” (2022)
                </li>
                <li>Audiokinetic - Guest, “Wwise Up On Air” (2021)</li>
                <li>
                  VGMtogether - Speaker, "Piecing it Together: A Deep Dive Into
                  the Interactive Music Systems of Divinuet” (2021)
                </li>
                <li>Game Audio LA - Co-organizer (2018-2020)</li>
                <li>
                  High Score - Panelist, “Where The Music Leads - Music Based
                  Gaming” (2020)
                </li>
                <li>
                  GDC - Panelist, “Making It Happen: Creating Their Own Games”
                  (2020) [canceled due to COVID]
                </li>
                <li>
                  Audiokinetic - Blog Contributor, “The Generative Music Systems
                  of Divinuet” (2019, 2020)
                </li>
                <li>
                  GameSoundCon - Panelist, “Making It Happen: Creating Their Own
                  Games” (2019)
                </li>
                <li>Faking Notes - Podcast Guest (2019)</li>
                <li>Bards of Gaming - Podcast Guest (2019)</li>
                <li>Rock Out With Your Cart Out - Podcast Guest (2019)</li>
              </ul>
              <H3Small style={{ margin: "0" }}>Recognition & such</H3Small>
              <H4>AWARDS — WON</H4>
              <ul>
                <li>
                    Step Up Your Sound Game Jam 2023- Winning Game (WHICH IS WITCH) [with Violet Eris and Cregg Hancock]
                </li>
                <li>
                  Spillprisen 2020- Best Audio (HELHEIM HASSLE) [with Sondre
                  Jensen, Oda Tilset, and Ali Cedroni]
                </li>
                <li>Tropfest 2018- Best Original Score (I'M STILL HERE)</li>
                <li>T.O. Webfest 2018- Best Music (THIS IS DESMONDO RAY!)</li>
                <li>
                  Southern Short Awards 2017- Award of Merit for Original Music
                  (SPACEMAN)
                </li>
              </ul>
              <H4>AWARDS — NOMINATED</H4>
              <ul>
                <li>
                  Ozark Mountain Webfest 2018- Best Overall Music (THIS IS
                  DESMONDO RAY!)
                </li>
                <li>
                  Rio Webfest 2018 - Best Soundtrack (THIS IS DESMONDO RAY!)
                </li>
                <li>
                  Vancouver Webfest 2018- Best Original Score (THIS IS DESMONDO
                  RAY!)
                </li>
              </ul>
              <H4>OTHER COOL STUFF</H4>
              <ul>
                <li>Featured game, itch.io recommends (2019, INTER-VIEW)</li>
                <li>Featured artist, Sainte Magazine (2018)</li>
                <li>
                  Participant composer, highSCORE New Music Festival (2012)
                </li>
              </ul>
            </section>
          </DivTextBody>
          <Aside>
            <StaticImage
              src="../images/portrait.png"
              alt="black and white photo of Megan smiling big among plants in a conservatory"
            />
          </Aside>
        </DivTwoColumn>
      </section>
    </Layout>
  )
}

export default AboutPage

export const Head = () => <Seo title={pageTitle} />
